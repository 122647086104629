:root {
  --primary: RGB(227, 48, 87);
  --secondary: #676183;
  --lightgray:#F4F6F9
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background: rgb(66, 66, 66,0.3);
  /* border-radius: 10px; */
}

html::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}
.makeStyles-root-702 > * {
  width: 100%;
  margin: 0px !important;
}

.makeStyles-root-230 > * {
  width: 100%;
  margin: 0px !important;
}
a{text-decoration: none !important; color: rgb(88, 166, 255)}
a:visited{color: #8fc0eb;}
.bg-image {
  min-height: 110vh;
  background-color: rgb(73, 71, 71);
  background-image: url("https://firebasestorage.googleapis.com/v0/b/millions-video.appspot.com/o/cover-image%2Fsirisvisual-IcwAKUhNGXs-unsplash.jpg?alt=media&token=04d4e645-194f-4b15-8b26-1f9f4c1f2f02");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.vjs-fullscreen-control{
  /* display: none !important; */
}
.rightclick-disable {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  }
.m2{
  margin:8px !important;
}

body{overflow-x: hidden;}
.ais-Stats-text{
  color: gray;
  font-size: 10px;
}
.clamp-lines__button{
  color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "ubuntu", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.bg-overlay {
  height: 100%;
  width: 100%;
  background-color: #676183;
  opacity: 25%;
}

.landing-start {
  padding: 10%;
}

.landing-hero-text {
  width: 75%;
  color: var(--primary);
}

.landing-hero-text .hero {
  font-size: 3.3rem;
}

.sign-in {
  text-align: center;
  padding: 10%;
}

.profile-form {
  padding-top: 40px;
  align-items: left;
  text-align: left;
}

#clamped-content-rec-title{display: block;
  margin-block-start: 0em;
  font-size:14px;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  width:80%;
  margin-inline-end: 0px;}
.grey {
  color: var(--secondary);
}

.sidebar-item {
  padding-top: 5px;
  /* min-height: 100px; */

}

.siebar-item-inner {
  padding: 5px;
  margin:7px;
  min-height: 50px;
  align-items: center;
  text-align: center;
  border-radius: 10px;
}

.sidebar-item-selected {
  color: var(--primary);
  
}

.upload-zone{
  background-color: var(--lightgray);
  min-height:30vh;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.pt-3{
  padding-top:3px
}
.pb-3{
  padding-bottom:3px
}
.upload-zone-small{
  background-color: var(--lightgray);
 height:120px;
 width:214px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}
.notselected{
  filter:opacity(50%);

}
.selected{
  filter:opacity(100%);
  border: 5px solid var(--primary);

}
.thumb{min-height: 140px;}
.text-muted{color: #bdbdbd;}

.upload-status{ background-color: #E8E8E8;border-radius: 0 0 5px 5px;}

.ais-Hits-item, .ais-InfiniteHits-item {

  background: transparent !important;

}

#clamped-content-really-unique-id {
  display: block;
  margin-block-start: 7px !important;
  margin-block-end: 0em  !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.makeStyles-root-283 > * {
  width: 100%;
  margin: 0px !important;
}

