@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap);
body {
  width:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: RGB(227, 48, 87);
  --secondary: #676183;
  --lightgray:#F4F6F9
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background: rgb(66, 66, 66,0.3);
  /* border-radius: 10px; */
}

html::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}
.makeStyles-root-702 > * {
  width: 100%;
  margin: 0px !important;
}

.makeStyles-root-230 > * {
  width: 100%;
  margin: 0px !important;
}
a{text-decoration: none !important; color: rgb(88, 166, 255)}
a:visited{color: #8fc0eb;}
.bg-image {
  min-height: 110vh;
  background-color: rgb(73, 71, 71);
  background-image: url("https://firebasestorage.googleapis.com/v0/b/millions-video.appspot.com/o/cover-image%2Fsirisvisual-IcwAKUhNGXs-unsplash.jpg?alt=media&token=04d4e645-194f-4b15-8b26-1f9f4c1f2f02");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.vjs-fullscreen-control{
  /* display: none !important; */
}
.rightclick-disable {
  -webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  }
.m2{
  margin:8px !important;
}

body{overflow-x: hidden;}
.ais-Stats-text{
  color: gray;
  font-size: 10px;
}
.clamp-lines__button{
  color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "ubuntu", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-justify-content: center;
            justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.bg-overlay {
  height: 100%;
  width: 100%;
  background-color: #676183;
  opacity: 25%;
}

.landing-start {
  padding: 10%;
}

.landing-hero-text {
  width: 75%;
  color: RGB(227, 48, 87);
  color: var(--primary);
}

.landing-hero-text .hero {
  font-size: 3.3rem;
}

.sign-in {
  text-align: center;
  padding: 10%;
}

.profile-form {
  padding-top: 40px;
  -webkit-align-items: left;
          align-items: left;
  text-align: left;
}

#clamped-content-rec-title{display: block;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  font-size:14px;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  width:80%;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;}
.grey {
  color: #676183;
  color: var(--secondary);
}

.sidebar-item {
  padding-top: 5px;
  /* min-height: 100px; */

}

.siebar-item-inner {
  padding: 5px;
  margin:7px;
  min-height: 50px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  border-radius: 10px;
}

.sidebar-item-selected {
  color: RGB(227, 48, 87);
  color: var(--primary);
  
}

.upload-zone{
  background-color: #F4F6F9;
  background-color: var(--lightgray);
  min-height:30vh;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.pt-3{
  padding-top:3px
}
.pb-3{
  padding-bottom:3px
}
.upload-zone-small{
  background-color: #F4F6F9;
  background-color: var(--lightgray);
 height:120px;
 width:214px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}
.notselected{
  -webkit-filter:opacity(50%);
          filter:opacity(50%);

}
.selected{
  -webkit-filter:opacity(100%);
          filter:opacity(100%);
  border: 5px solid RGB(227, 48, 87);
  border: 5px solid var(--primary);

}
.thumb{min-height: 140px;}
.text-muted{color: #bdbdbd;}

.upload-status{ background-color: #E8E8E8;border-radius: 0 0 5px 5px;}

.ais-Hits-item, .ais-InfiniteHits-item {

  background: transparent !important;

}

#clamped-content-really-unique-id {
  display: block;
  -webkit-margin-before: 7px !important;
          margin-block-start: 7px !important;
  -webkit-margin-after: 0em  !important;
          margin-block-end: 0em  !important;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.makeStyles-root-283 > * {
  width: 100%;
  margin: 0px !important;
}


video-js .vjs-menu-button-inline.vjs-slider-active,.video-js .vjs-menu-button-inline:focus,.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-control {
    width: 3em
}

.video-js .vjs-menu-button-inline:before {
    width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button {
    display: block
}

.video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
    padding: 0 .4em .3em !important
}

.video-js.vjs-ended .vjs-loading-spinner {
    display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
    display: block !important;
}

.video-js *,.video-js:after,.video-js:before {
    box-sizing: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit
}

.video-js.vjs-fullscreen,.video-js.vjs-fullscreen .vjs-tech {
    width: 100%!important;
    height: 100%!important
}

.video-js {
    font-size: 14px;
    overflow: hidden
}

.video-js .vjs-control {
    color: inherit
}

.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 8.35em
}

.video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    height: 3em;
    width: 6.35em
}

.video-js .vjs-control:focus:before,.video-js .vjs-control:hover:before {
    text-shadow: 0 0 1em #fff,0 0 1em #fff,0 0 1em #fff
}

.video-js .vjs-spacer,.video-js .vjs-time-control {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto
}

.video-js .vjs-time-control {
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    width: auto
}

.video-js .vjs-time-control.vjs-time-divider {
    width: 14px
}

.video-js .vjs-time-control.vjs-time-divider div {
    width: 100%;
    text-align: center
}

.video-js .vjs-time-control.vjs-current-time {
    margin-left: 1em
}

.video-js .vjs-time-control .vjs-current-time-display,.video-js .vjs-time-control .vjs-duration-display {
    width: 100%
}

.video-js .vjs-time-control .vjs-current-time-display {
    text-align: right
}

.video-js .vjs-time-control .vjs-duration-display {
    text-align: left
}

.video-js .vjs-play-progress:before,.video-js .vjs-progress-control .vjs-play-progress:before,.video-js .vjs-remaining-time,.video-js .vjs-volume-level:after,.video-js .vjs-volume-level:before,.video-js.vjs-live .vjs-time-control.vjs-current-time,.video-js.vjs-live .vjs-time-control.vjs-duration,.video-js.vjs-live .vjs-time-control.vjs-time-divider,.video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none
}

.video-js.vjs-no-flex .vjs-time-control {
    display: table-cell;
    width: 4em
}

.video-js .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: .5em;
    top: -.5em
}

.video-js .vjs-progress-control .vjs-load-progress,.video-js .vjs-progress-control .vjs-play-progress,.video-js .vjs-progress-control .vjs-progress-holder {
    height: 100%
}

.video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0
}

.video-js .vjs-progress-control:hover {
    height: 1.5em;
    top: -1.5em
}

.video-js .vjs-control-bar {
    transition: -webkit-transform .1s ease 0s;
    transition: transform .1s ease 0s;
    transition: transform .1s ease 0s, -webkit-transform .1s ease 0s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
    visibility: visible;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateY(3em);
    transform: translateY(3em);
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
    transition: transform 1s ease 0s, -webkit-transform 1s ease 0s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
    height: .25em;
    top: -.25em;
    pointer-events: none;
    transition: height 1s,top 1s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
    opacity: 0;
    transition: opacity 1s ease 1s
}

.video-js.vjs-live .vjs-live-control {
    margin-left: 1em
}

.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-left: -1em;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border: none;
    border-radius: 50%;
    font-size: 3.5em;
    background-color: rgba(0,0,0,.45);
    color: #fff;
    transition: border-color .4s,outline .4s,background-color .4s
}

.video-js .vjs-menu-button-popup .vjs-menu {
    left: -3em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: transparent;
    width: 12em;
    left: -1.5em;
    padding-bottom: .5em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item,.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: #151b17;
    margin-right: .7em;
    padding: .5em;
    border-radius: 0
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    background-color: #2483d5
}

.video-js .vjs-big-play-button {
    background-color: rgba(0,0,0,0.35);
    font-size: 3.5em;
    border-radius: 50%;
    height: 2em !important;
    line-height: 2em !important;
    margin-top: -1em !important
}

.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background-color: rgba(0,0,0,0.35)
}

.video-js .vjs-loading-spinner {
    border-color: rgba(255,255,255,0.7)
}

.video-js .vjs-control-bar2 {
    background-color: transparent
}

.video-js .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: #ffffff;
    font-size: 11px
}

.video-js .vjs-play-progress,.video-js  .vjs-volume-level {
    background-color: #d5244d
}

.video-js .vjs-big-play-button {
    height: 2em !important;
    width: 2em !important;
    line-height: 1.9em !important;
    margin-top: -1em !important;
    margin-left: -1em;
    border-width: 3px
}

.video-js .vjs-icon-play:before, .video-js .vjs-big-play-button:before {
    font-size: 50px;
}

.video-js  .vjs-progress-holder {
    font-size: 1.7em;
    border-radius: 10px;
}

.video-js .vjs-progress-holder .vjs-play-progress, .video-js .vjs-progress-holder .vjs-load-progress, .video-js .vjs-progress-holder .vjs-load-progress div, .video-js .vjs-slider,.vjs-volume-level {
    border-radius: 10px;
}

.video-js .vjs-load-progress {
    background: rgba(255,255,255,0.5);
}

.vjs-playback-rate .vjs-playback-rate-value {
    pointer-events: none;
    font-size: inherit;
    line-height: 3;
    text-align: center;
}
